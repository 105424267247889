// import logoDark from "@/images/logo.png";
import logoDark from "@/images/logo.png";
import slideOneImage1 from "@/images/slider/slider-1.jpg";
import slideOneImage2 from "@/images/slider/slider-2.jpg";
import slideOneImage3 from "@/images/slider/slider-3.jpg";
import serviceImage1 from "@/images/services/service-1.jpg";
import aboutImage from "@/images/services/aboutImage.jpg";
import serviceImage2 from "@/images/services/service-2.jpg";
import serviceImage3 from "@/images/services/service-3.jpg";
import flatImage from "@/images/flatImage.jpg";
import funfactShape from "@/images/shape/about-shape.png";
import galleryOne1 from "@/images/gallery/gallery-1.jpg";
import galleryOne2 from "@/images/gallery/gallery-2.jpg";
import galleryOne3 from "@/images/gallery/gallery-3.jpg";
import galleryOne4 from "@/images/gallery/gallery-4.jpg";
import galleryOne5 from "@/images/gallery/gallery-5.jpg";
import galleryOne6 from "@/images/gallery/gallery-6.jpg";
import blog1 from "@/images/blog/blog-1.jpg";
import blog2 from "@/images/blog/blog-2.jpg";
import blog3 from "@/images/blog/blog-3.jpg";
import headDark from "@/images/travelbag-banner.jpg";

export const Logo = {
  dark: logoDark,
  title: "logo",
};
export const HeadPop = {
  head: headDark,
  title: "headPop",
};
export const NavLinksData = [
  {
    name: "Home",
    url: "/",
    // subItems: [
    //   {
    //     name: "Home 01",
    //     url: "/"
    //   },
    //   {
    //     name: "Home 02",
    //     url: "/index-2"
    //   }
    // ]
  },
  {
    name: "Services",
    url: "/services",
    // subItems: [
    //   {
    //     name: "Services",
    //     url: "/service"
    //   },
    //   {
    //     name: "Service Details",
    //     url: "/service-details"
    //   }
    // ]
  },
  {
    name: "Blog",
    url: "/blog",
    // subItems: [
    //   {
    //     name: "Blog Page",
    //     url: "/blog"
    //   },
    //   {
    //     name: "Blog Details",
    //     url: "/blog-details"
    //   }
    // ]
  },
  {
    name: "About",
    url: "/about",
  },
  {
    name: "Contact",
    url: "/contact",
  },
];

export const TopbarInfos = [
  {
    icon: "icon-placeholder",
    tagLine: "Address",
    text: "Max Trade Centre, 23 Luk Hop St, San Po Kong, Hong Kong",
    url: "#",
  },
  {
    icon: "icon-phone-call",
    tagLine: "Call Now",
    text: "+852 9888 2051",
    url: "tel:+852-9888-2051",
  },
  {
    icon: "icon-clock",
    tagLine: "Open Hours",
    text: " Mon-Sat: 9am - 6pm",
    url: "#",
  },
];

export const SlideOneData = [
  {
    backgroundImage: slideOneImage1,
    title: "Resolve Your Pest Issues Quickly",
    text: "Flick Away Pests",
    button: "GET A FREE QUOTE",
    url: "/contact",
  },
  {
    backgroundImage: slideOneImage2,
    title: "Confirmed Quality in Cheapest Price ",
    text: "Quality with Affordability",
    button: "GET A FREE QUOTE",
    url: "/contact",
  },
  {
    backgroundImage: slideOneImage3,
    title: "Eliminate Pests with Our Modern Solutions",
    text: "Avoid Pest Nuisances",
    button: "GET A FREE QUOTE",
    url: "/contact",
  },
];

export const FeatureOneData = [
  {
    icon: "icon-wallet",
    title: "No Prepayment Needed",
  },
  {
    icon: "icon-diploma",
    title: "Promised Satisfaction",
  },
  {
    icon: "icon-big_clock",
    title: "Round-the-clock Services",
  },
  {
    icon: "icon-handshake",
    title: "Extensive Proficiency",
  },
  {
    icon: "icon-mechanic",
    title: "Rapid Pest Termination",
  },
];

export const AboutOneData = {
  image: aboutImage,
  title: "Rapid Pest Extermination Was Never Easier Before",
  tagLine: "Welcome to Nifixeci",
  content:
    "To save the whole of Hong Kong from frequent pest problems, Nifixeci has come up with modern and quick pest extermination solutions that guarantee both the finest quality and durable efficiency.",
  button: {
    url: "/about",
    label: "LEARN MORE",
  },
};

export const ServiceOneData = {
  title: "Our Offerings",
  posts: [
    {
      image: serviceImage1,
      icon: "icon-snow",
      title: "Bedbug Extermination",
      text: "Our eco-conscious products and techniques effectively remove all bedbugs for a joyful sleep.",
      button: {
        label: "LEARN MORE",
        url: "/bedbug-extermination",
      },
    },
    {
      image: serviceImage2,
      icon: "icon-fire",
      title: "Mosquito Extermination",
      text: "Avoid getting bitten frequently and any mosquito-borne illness with our finest mosquito extermination solution.",
      button: {
        label: "LEARN MORE",
        url: "/mosquito-extermination",
      },
    },
    {
      image: serviceImage3,
      icon: "icon-ac",
      title: "Cockroach Extermination",
      text: "Stay annoyance-free from cockroaches by availing of our efficient cockroach removal solutions.",
      button: {
        label: "LEARN MORE",
        url: "/cockroach-extermination",
      },
    },
  ],
};

export const CallToActionOneData = {
  title: "Avail Our State-of-the-art Pest Extermination Services",
  button: {
    url: "/contact",
    label: "GET A FREE QUOTE",
  },
};

export const CallToActionTwoData = {
  left: {
    text: "Our Affordability Commitment",
    title: "Low-cost Extermination",
    umg: flatImage,
  },
  right: {
    text: "Reliable Pest Management",
    title: "Say Good-bye to Pests",
    umg: flatImage,
  },
};

export const FunfactOneData = {
  shapeImage: funfactShape,
  title: "Numbers Speakers.",
  posts: [
    {
      count: 8870,
      text: "Air Conditioning Repairs and Install",
    },
    {
      count: 4760,
      text: "Air Conditioning Repairs and Install",
    },
    {
      count: 9032,
      text: "Air Conditioning Repairs and Install",
    },
  ],
};

export const GalleryOneData = [
  {
    title: "Planet-conscious Solutions",
    image: galleryOne1,
    url: "#",
  },
  {
    title: "Proficient Technicians",
    image: galleryOne2,
    url: "#",
  },
  {
    title: "Guaranteed Positive Results",
    image: galleryOne3,
    url: "#",
  },
  {
    title: "Harmless Handling",
    image: galleryOne4,
    url: "#",
  },
  {
    title: "24*7 Convenience",
    image: galleryOne5,
    url: "#",
  },
  {
    title: "Robust Prevention",
    image: galleryOne6,
    url: "#",
  },
];

export const FooterBottomData = {
  social: [
    {
      icon: "fa fa-twitter",
      url: "https://x.com/Nifixeci",
    },
    {
      icon: "fa fa-pinterest",
      url: "https://pinterest.com/nifixeci/",
    },
  ],
};

export const FooterInfo = [
  {
    icon: "icon-placeholder",
    tagLine: "Address",
    text: "Max Trade Centre, 23 Luk Hop St, San Po Kong, Hong Kong",
    url: "#",
  },
  {
    icon: "icon-contact",
    tagLine: "Email",
    text: "info@nifixeci.com",
    url: "mailto:info@nifixeci.com",
  },
  {
    icon: "icon-clock",
    tagLine: "Open Hours",
    text: " Mon-Sat: 9am - 6pm",
    url: "#",
  },
];

export const FooterWidgets = {
  about: {
    title: "About Nifixeci",
    text: "At Nifixeci, we prioritize our client's needs to effectively cater to them with optimal pest extermination solutions while keeping a nourishing surrounding.",
    tagLine: "Get a Quote",
    phone: "+852 9888 2051",
  },
  linkOne: {
    title: "Quick Links",
    menu: [
      {
        lable: "Home",
        url: "/",
      },
      {
        lable: "Services",
        url: "/services",
      },
      {
        lable: "Blog",
        url: "/blog",
      },
      {
        lable: "About Us",
        url: "/about",
      },
      {
        lable: "Contact",
        url: "/contact",
      },
    ],
  },
  linkTwo: {
    title: "Help",
    menu: [
      {
        lable: "Terms & Conditions",
        url: "/terms-conditions",
      },
      {
        lable: "Privacy Policy",
        url: "/privacy-policy",
      },
      // {
      //   lable: "Change Location",
      //   url: "#",
      // },
      // {
      //   lable: "Expert Tips",
      //   url: "#",
      // },
      // {
      //   lable: "Terms & Conditions",
      //   url: "#",
      // },
    ],
  },
};

// export const BlogOneData = {
//   title: "Our latest news",
//   posts: [
//     {
//       image: blog1,
//       date: "07 Nov",
//       author: "admin",
//       commentCount: 3,
//       title: "How does a ductless heat pump work?",
//       url: "/blog-details",
//       text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
//     },
//     {
//       image: blog2,
//       date: "07 Nov",
//       author: "admin",
//       commentCount: 3,
//       title: "Simple hack to improve A/C efficiency",
//       url: "/blog-details",
//       text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
//     },
//     {
//       image: blog3,
//       date: "07 Nov",
//       author: "admin",
//       commentCount: 3,
//       title: "Preparing your home before vacation",
//       url: "/blog-details",
//       text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
//     },
//   ],
// };

export const BlogTwoData = {
  posts: [
    {
      image: blog1,
      date: "03 Sep",
      author: "admin",
      commentCount: 3,
      title:
        "Eco-conscious Pest Control: Why It Matters for Your Home and Wellbeing",
      url: "/eco-conscious-pest-control",
      text: "We are now living in a time when we are more worried about our earth's future than anything else. The past century's industrial evolution has contributed significant negative influences on our environment. The air, the water, and the soil, all got contaminated and still is, which has led to severe climate change.",
      description: "",
    },
    {
      image: blog2,
      date: "05 Sep",
      author: "admin",
      commentCount: 3,
      title: "Seasonal Pest Control: What to Expect and How to Prepare",
      url: "/seasonal-pest-control",
      text: "Pests exist throughout the year. Some are more active during a certain period of the year, while there are the ones that are active throughout the year. Experts say that the main aspect at the heart of controlling and terminating pest issues is by understanding their behavior in different times of the year.",
      description: "",
    },
    {
      image: blog3,
      date: "07 Sep",
      author: "admin",
      commentCount: 3,
      title: "4 Common Household Pests in Hong Kong and How to Prevent Them",
      url: "/common-household-pests",
      text: "Being a special administrative region of China, Hong Kong poses a completely different climate. While China experiences mostly a cold weather, Hong Kong is extremely different. Identified as a subtropical area, Hong Kong has an interesting climate.",
      description: "",
    },
    // {
    //   image: blog4,
    //   date: "07 Nov",
    //   author: "admin",
    //   commentCount: 3,
    //   title: "Heating and cooling safety issues",
    //   url: "/blog-details",
    //   text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
    // },
    // {
    //   image: blog5,
    //   date: "07 Nov",
    //   author: "admin",
    //   commentCount: 3,
    //   title: "Don’t neglect your outdoor unit",
    //   url: "/blog-details",
    //   text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
    // },
    // {
    //   image: blog6,
    //   date: "07 Nov",
    //   author: "admin",
    //   commentCount: 3,
    //   title: "Have your system professionally inspected",
    //   url: "/blog-details",
    //   text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
    // },
  ],
};

export const VideoOneData = {
  title: "Watch how we work",
  id: "8DP4NgupAhI",
};

export const AboutTwoData = {
  caption: "Distinctive Pest Solutions",
  blockTitle: {
    title:
      "Trust is a two-way street and we earn yours by offering unmatched pest extermination services.",
    tagline: "Reasons to Trust Us",
  },
  lists: [
    {
      text: "Holistic & Affordable Pest Control Packages",
    },
    {
      text: "Sustainable Pest Removal Solutions",
    },
    {
      text: "Talented & Qualified Technicians",
    },
    {
      text: "24*7 Rapid Response Team",
    },
    {
      text: "Non-Hazardous & Harmless Handling Methods",
    },
    {
      text: "Proven & Enduring Results",
    },
  ],
  offer: {
    tagline: "Discounts",
    text: "For Our Senior Citizen Clients",
  },
};

export const AboutThreeData = {
  blockTitle: {
    tagline: "Our success story",
    title: "Experience quality air conditioning service in broklyn new york",
  },
  summery:
    "There are many variations of passages of lorem ipsum available, but the majorify have suffered alteration in some form by injected humour, or randmised words which don't look even slightly believable.",
  button: {
    lable: "LEARN MORE",
    url: "/about",
  },
  box: [
    {
      icon: "icon-maintenance",
      title: "We make as cool again",
    },
    {
      icon: "icon-big-ac",
      title: "Keep your business cool",
    },
  ],
};

export const AboutFourData = {
  blockTitle: {
    title: "Nifixeci is your trusted pest control partner",
    text1:
      "Nifixeci is among the successful pest management service providers in Hong Kong. With talented exterminators under its inventory, the company is known for its high-quality solutions",
    text2:
      "Our mission is to provide outstanding pest control solutions with a mixture of traditional and contemporary methods. Our vision is to revolutionize the pest control industry through innovative solutions.",
  },
};

export const BenefitOneData = {
  title: "Key Advantages",
  posts: [
    {
      icon: "icon-big_clock",
      title: "Round-the-clock Services",
      text: "You can ask for our help anytime to address any pest-related issues.",
    },
    {
      icon: "icon-wallet",
      title: "No Prepayment Needed",
      text: "We don’t charge a single penny before your pest removal work ends.",
    },
    {
      icon: "icon-diploma",
      title: "Promised Satisfaction",
      text: "We’ve a zero-tolerance policy against poor quality to guarantee your satisfaction.",
    },
    {
      icon: "icon-mechanic",
      title: "Extensive Proficiency",
      text: "Our technicians are talented, capable, and proficient.",
    },
  ],
};

export const ServiceSidebarListData = [
  {
    icon: "icon-snow",
    title: "Cooling Services",
    list: [
      {
        label: "Air Conditioner Maintenance",
        url: "#",
      },
      {
        label: "Air Conditioner Repair",
        url: "#",
      },
      {
        label: "Air Conditioner Replacement",
        url: "#",
      },
      {
        label: "Ductless Split AC Systems",
        url: "#",
      },
      {
        label: "Duct Cleaning & Installation",
        url: "#",
      },
    ],
  },
  {
    icon: "icon-fire",
    title: "Heating Services",
    list: [
      {
        label: "Air Conditioner Maintenance",
        url: "#",
      },
      {
        label: "Air Conditioner Repair",
        url: "#",
      },
      {
        label: "Air Conditioner Replacement",
        url: "#",
      },
      {
        label: "Ductless Split AC Systems",
        url: "#",
      },
      {
        label: "Duct Cleaning & Installation",
        url: "#",
      },
    ],
  },
];

export const ServiceSidebarOtherData = [
  {
    icon: "icon-ac",
    title: "Other Services",
    content: {
      icon: "icon-support",
      title: "<span>Contact with us </span>+852 9888 2051",
    },
  },
  {
    icon: "icon-building",
    title: "Commercial Services",
    content: {
      icon: "icon-building",
      title: "<span>Contact with us </span>+852 9888 2051",
    },
  },
];
